<template>
  <div>
    <h2 class="font-weight-bolder font-medium-4">
      {{ $t('Quotes') }}
    </h2>
    <b-row>
      <b-col>
        <component
          :is="rentalOrdersFields[field].type"
          v-for="field in ['rental_sales_orders_price_tier_sales_id', 'rental_sales_orders_price_tier_rentals_id']"
          :key="field"
          :ref="`${field}_ref`"
          v-model="rentalOrders[field]"
          v-bind="getProps(field, rentalOrdersFields)"
          :is-editable="!$can(ACCESS_ABILITY_SETTINGS_ORDERS.action,ACCESS_ABILITY_SETTINGS_ORDERS.subject) || isEdit"
        />
      </b-col>
      <b-col>
        <component
          :is="rentalOrdersFields[field].type"
          v-for="field in ['rental_sales_orders_hours_before_marketing', 'rental_sales_orders_allow_hold']"
          :key="field"
          :ref="`${field}_ref`"
          v-model="rentalOrders[field]"
          v-bind="getProps(field, rentalOrdersFields)"
          :is-editable="!$can(ACCESS_ABILITY_SETTINGS_ORDERS.action,ACCESS_ABILITY_SETTINGS_ORDERS.subject) || isEdit"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import ErrorAlert from '@/views/components/ErrorAlert.vue'
import { BCol, BRow } from 'bootstrap-vue'
import config from '@/views/settings/orders/rental-orders/rentalOrdersConfig'
import { getProps } from '@core/utils/utils'

export default {
  name: 'RentalOrdersQuotes',

  components: { ErrorAlert, BRow, BCol },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    rentalOrders() {
      return this.$store.state[this.MODULE_NAME].rentalOrdersForm
    },
  },
  setup() {
    const { MODULE_NAME, rentalOrdersFields, ACCESS_ABILITY_SETTINGS_ORDERS } = config()

    return {
      MODULE_NAME,
      rentalOrdersFields,
      ACCESS_ABILITY_SETTINGS_ORDERS,
      getProps,
    }
  },
}
</script>
<style scoped>

</style>
