import crudActions from '@/libs/storeConstActions'
import axios from '@/libs/axios'

const endpoint = 'settings/order/rental-order'

const Actions = crudActions(endpoint)
const getRentalOrders = (ctx, data) => axios.get(`${endpoint}`, data)
const updateRentalOrders = (ctx, data) => axios.post(`${endpoint}`, data)
export default {
  ...Actions,
  getRentalOrders,
  updateRentalOrders,
}
