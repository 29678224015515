import {
  CHECKBOX_LEF, SELECT_INPUT, TEXT_INPUT, TEXTAREA_INPUT,
} from '@/views/components/DynamicForm/constants'

export default function config() {
  const MODULE_NAME = 'set-order-rental-orders'
  const MODULE_NAME_CLONE = 'cloneData'
  const ACCESS_ABILITY_SETTINGS_ORDERS = { action: 'change', subject: 'Catalog' }
  const rentalOrdersFields = {

    rental_sales_orders_price_tier_sales_id: {
      type: SELECT_INPUT,
      label: 'Price Tier to use for sales',
      store: 'priceTierList',
      options: {
        label: 'label',
      },
      rules: 'required',
    },
    rental_sales_orders_price_tier_rentals_id: {
      type: SELECT_INPUT,
      label: 'Price Tier to use for rentals',
      store: 'priceTierList',
      options: {
        label: 'label',
      },
      rules: 'required',
    },
    rental_sales_orders_hours_before_marketing: {
      type: TEXT_INPUT,
      label: 'Hours before marking quotes as warning',
      rules: 'required|numeric',
      placeholder: 'Type...',
    },
    rental_sales_orders_allow_hold: {
      type: CHECKBOX_LEF,
      placeholder: 'Rental Quotes',
      label: 'Allow hold inventory on quote',
      rules: 'required',
    },
    rental_sales_orders_approvals_allow_pick: {
      type: CHECKBOX_LEF,
      placeholder: 'Rental Quotes',
      label: 'Allow pick/pack without approved ',
      rules: 'required',
    },
    rental_sales_orders_approvals_allow_hold: {
      type: CHECKBOX_LEF,
      placeholder: 'Rental Quotes',
      label: 'Allow hold inventory on quote',
      rules: 'required',
    },
    rental_sales_orders_templates_email: {
      type: TEXTAREA_INPUT,
      label: 'Email',
      rules: 'required',
      options: {
        class: 'text-dark',
        rows: 8,
      },
    },
    rental_sales_orders_templates_pdf: {
      type: TEXTAREA_INPUT,
      label: 'PDF version',
      rules: 'required',
      options: {
        class: 'text-dark',
        rows: 8,
      },
    },
  }
  return {
    rentalOrdersFields,
    ACCESS_ABILITY_SETTINGS_ORDERS,
    MODULE_NAME,
    MODULE_NAME_CLONE,
  }
}
