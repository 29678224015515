<template>
  <div>
    <skeleton v-if="loading" />
    <rental-orders-form
      v-else
      ref="rental-orders-form"
      @refetch="refetch"
    />
  </div>
</template>
<script>
import config from '@/views/settings/orders/rental-orders/rentalOrdersConfig'
import rentalOrdersModule from '@/store/settings/orders/rental-orders'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import RentalOrdersForm from '@/views/settings/orders/rental-orders/components/RentalOrdersForm.vue'
import Skeleton from '@/views/settings/orders/rental-orders/components/Skeleton.vue'

export default {
  name: 'RentalOrders',
  components: { Skeleton, RentalOrdersForm },
  data() {
    return {
      loading: false,
    }
  },
  created() {
    this.$emit('updateMenu', 'settings-orders-rental')
    this.refetch()
  },
  beforeRouteLeave(to, from, next) {
    if (this.$refs['rental-orders-form']) this.$refs['rental-orders-form'].showConfirmationModal(next)
  },
  methods: {
    refetch() {
      this.loading = true
      this.$store.dispatch(`${this.MODULE_NAME}/getRentalOrders`).then(res => {
        const { data } = res.data
        this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, data)
        this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_ORDER_RENTAL_ORDERS_CLONE`, data)
      }).catch(err => {
        this.error = true
        this.errorNotification(this, err)
      }).finally(() => {
        this.loading = false
      })
    },
  },
  setup() {
    const { MODULE_NAME, MODULE_NAME_CLONE, rentalOrdersFields } = config()
    if (!store.hasModule(MODULE_NAME)) store.registerModule(MODULE_NAME, rentalOrdersModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MODULE_NAME)) store.unregisterModule(MODULE_NAME)
    })
    return {
      MODULE_NAME,
      MODULE_NAME_CLONE,
      rentalOrdersFields,
    }
  },
}
</script>
