<template>
  <div>
    <div class="d-flex justify-content-end">
      <feather-icon
        v-if="organizationRequiresEditConfirmation"
        v-b-tooltip.noninteractive.hover.bottom
        :title="$t('Edit')"
        :icon="isEdit ? 'LEditIconUpdated' : 'LNoneEditIconUpdated'"
        size="36"
        class="ml-1 cursor-pointer"
        @click="isEditAble()"
      />
    </div>
    <b-form>
      <error-alert
        :fields="rentalOrdersFields"
        :error="error"
      />
      <validation-observer
        ref="rentalOrdersUpdateVal"
      >
        <rental-orders-quotes :is-edit="isEdit" />
        <rental-orders-approvals :is-edit="isEdit" />
        <rental-orders-templates :is-edit="isEdit" />
        <portal to="body-footer">
          <div class="d-flex mt-2 pb-1 justify-content-between">
            <div>
              <b-button
                v-if="!isFormChanged"
                class="cancelBtn font-medium-1 font-weight-bolder"
                variant="outline-primary"
                @click="$router.push({ name: 'settings' })"
              >
                {{ $t('Back to Setting') }}
              </b-button>
              <b-button
                v-else
                class="cancelBtn font-medium-1 font-weight-bolder"
                variant="outline-primary"
                @click="loader"
              >
                <feather-icon
                  icon="LCancelIcon"
                  size="16"
                />
                {{ $t('Cancel') }}
              </b-button>
            </div>
            <div>
              <b-button
                variant="success"
                class="saveBtn font-medium-1 font-weight-bolder"
                :disabled="!isFormChanged"
                @click="submit"
              >
                {{ $t('SAVE') }}
              </b-button>
            </div>
          </div>
        </portal>
      </validation-observer>
    </b-form>
    <b-s-warning-modal ref="confirm-modal" />
  </div>
</template>
<script>
import { BButton, BForm, VBTooltip } from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import { required } from '@/libs/validations/validations'
import config from '@/views/settings/orders/rental-orders/rentalOrdersConfig'
import RentalOrdersQuotes from '@/views/settings/orders/rental-orders/components/RentalOrdersQuotes.vue'
import RentalOrdersApprovals from '@/views/settings/orders/rental-orders/components/RentalOrdersApprovals.vue'
import ErrorAlert from '@/views/components/ErrorAlert.vue'
import { scrollToError } from '@core/utils/utils'
import RentalOrdersTemplates from '@/views/settings/orders/rental-orders/components/RentalOrdersTemplates.vue'
import store from '@/store'

export default {
  name: 'RentalOrdersForm',
  components: {
    RentalOrdersApprovals,
    RentalOrdersTemplates,
    RentalOrdersQuotes,
    BButton,
    BForm,
    ErrorAlert,
    ValidationObserver,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      error: {},
      required,
      onSubmit: false,
      isEdit: store.state.app.organizationRequiresEditConfirmation,
    }
  },

  computed: {
    organizationRequiresEditConfirmation() {
      return store.state.app.organizationRequiresEditConfirmation
    },
    rentalOrders() {
      return this.$store.state[this.MODULE_NAME].rentalOrdersForm
    },
    rentalOrdersFormClone() {
      return this.$store.state[this.MODULE_NAME_CLONE].settingsRentalOrdersFormClone
    },
    isFormChanged() {
      return JSON.stringify(this.rentalOrders) !== this.rentalOrdersFormClone
    },
  },
  methods: {
    isEditAble() {
      this.isEdit = !this.isEdit
    },
    showConfirmationModal(next) {
      if (this.$refs['confirm-modal']) this.$refs['confirm-modal'].confirmWarning(next, this.isFormChanged, this.onSubmit)
    },
    submit() {
      this.error = {}
      this.onSubmit = true
      this.$refs.rentalOrdersUpdateVal.validate()
        .then(success => {
          if (success) {
            const data = this.rentalOrders

            const rentalOrders = this.mappingFields(Object.keys(this.rentalOrdersFields), data, {
              rental_sales_orders_price_tier_sales_id: data.rental_sales_orders_price_tier_sales_id.id,
              rental_sales_orders_price_tier_rentals_id: data.rental_sales_orders_price_tier_rentals_id.id,
              rental_sales_orders_hours_before_marketing: data.rental_sales_orders_hours_before_marketing,
              rental_sales_orders_allow_hold: data.rental_sales_orders_allow_hold ? 'true' : 'false',
              rental_sales_orders_approvals_allow_pick: data.rental_sales_orders_approvals_allow_pick ? 'true' : 'false',
              rental_sales_orders_approvals_allow_hold: data.rental_sales_orders_approvals_allow_hold ? 'true' : 'false',
              rental_sales_orders_templates_email: String(data.rental_sales_orders_templates_email),
              rental_sales_orders_templates_pdf: String(data.rental_sales_orders_templates_pdf),
            })
            this.sendNotification(this, rentalOrders, `${this.MODULE_NAME}/updateRentalOrders`)
              .then(() => {
                this.$emit('refetch')
                this.clear()
              })
              .catch(err => {
                this.error = err.response?.data
              }).finally(() => {
                this.onSubmit = false
              })
          } else {
            scrollToError(this, this.$refs.customerSettingsCreateVal)
          }
        })
    },
    loader() {
      this.$refs.rentalOrdersUpdateVal.reset()
      const smth = JSON.parse(this.rentalOrdersFormClone)
      this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, smth)
    },
    getProps(fieldName) {
      return {
        field: this.rentalOrdersFields[fieldName],
        name: fieldName,
      }
    },
  },
  setup() {
    const { rentalOrdersFields, MODULE_NAME, MODULE_NAME_CLONE } = config()

    return {
      rentalOrdersFields,
      MODULE_NAME,
      MODULE_NAME_CLONE,
    }
  },
}
</script>
