<template>
  <div>
    <h2 class="font-weight-bolder font-medium-4">
      {{ $t('Approvals') }}
    </h2>
    <b-row>
      <b-col md="6">
        <div
          v-for="field in ['rental_sales_orders_approvals_allow_pick', 'rental_sales_orders_approvals_allow_hold']"
          :key="field"
        >
          <component
            :is="rentalOrdersFields[field].type"
            :ref="`${field}_ref`"
            v-model="rentalOrders[field]"
            v-bind="getProps(field, rentalOrdersFields)"
            :is-editable="!$can(ACCESS_ABILITY_SETTINGS_ORDERS.action,ACCESS_ABILITY_SETTINGS_ORDERS.subject) || isEdit"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BCol, BRow } from 'bootstrap-vue'
import config from '@/views/settings/orders/rental-orders/rentalOrdersConfig'
import { getProps } from '@core/utils/utils'

export default {
  name: 'RentalOrdersApprovals',
  components: { BRow, BCol },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    rentalOrders() {
      return this.$store.state[this.MODULE_NAME].rentalOrdersForm
    },
  },
  setup() {
    const { MODULE_NAME, rentalOrdersFields, ACCESS_ABILITY_SETTINGS_ORDERS } = config()

    return {
      MODULE_NAME,
      rentalOrdersFields,
      ACCESS_ABILITY_SETTINGS_ORDERS,
      getProps,
    }
  },
}
</script>

<style scoped>

</style>
