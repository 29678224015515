import { render, staticRenderFns } from "./RentalOrdersApprovals.vue?vue&type=template&id=4615a816&scoped=true"
import script from "./RentalOrdersApprovals.vue?vue&type=script&lang=js"
export * from "./RentalOrdersApprovals.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4615a816",
  null
  
)

export default component.exports