<template>
  <div>
    <h2 class="font-weight-bolder font-medium-4">
      {{ $t('Quotes') }}
    </h2>
    <b-row>
      <b-col>
        <div
          v-for="field in ['rental_sales_orders_price_tier_sales_id', 'rental_sales_orders_price_tier_rentals_id']"
          :key="field"
          style="height: 70px; margin-bottom: 10px"
        >
          <span
            class="font-weight-bolder d-block"
            style="margin-bottom: 5px; margin-left: 10px"
          >{{ rentalOrdersFields[field].label }}</span>
          <b-skeleton
            height="30px"
            style="border-radius: 5px"
          />
        </div>
      </b-col>
      <b-col>
        <div
          v-for="field in ['rental_sales_orders_hours_before_marketing', 'rental_sales_orders_allow_hold']"
          :key="field"
          style="height: 70px; margin-bottom: 10px"
        >
          <span
            class="font-weight-bolder d-block"
            style="margin-bottom: 5px; margin-left: 10px"
          >{{ rentalOrdersFields[field].label }}</span>
          <b-skeleton
            height="30px"
            style="border-radius: 5px"
          />
        </div>
      </b-col>
    </b-row>

    <h2 class="font-weight-bolder font-medium-4">
      {{ $t('Approvals') }}
    </h2>
    <b-row>
      <b-col md="12">
        <div
          v-for="field in ['rental_sales_orders_approvals_allow_pick', 'rental_sales_orders_approvals_allow_hold']"
          :key="field"
          style="height: 70px; margin-bottom: 10px"
        >
          <b-skeleton
            height="30px"
            style="border-radius: 5px"
          />
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="12">
        <div
          v-for="field in ['rental_sales_orders_templates_email', 'rental_sales_orders_templates_pdf']"
          :key="field"
          style="height: 170px; margin-bottom: 10px"
        >
          <span
            class="font-weight-bolder d-block"
            style="margin-bottom: 5px; margin-left: 10px"
          >{{ rentalOrdersFields[field].label }}</span>
          <b-skeleton
            height="150px"
            style="border-radius: 5px"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { BCol, BRow, BSkeleton } from 'bootstrap-vue'
import config from '../rentalOrdersConfig'

export default {
  name: 'RentalOrdersSkeleton',
  components: { BRow, BCol, BSkeleton },
  setup() {
    const { rentalOrdersFields } = config()
    return {
      rentalOrdersFields,
    }
  },
}
</script>
